
export const environment = {
  production: false,
  URL: 'https://clasificadoscontacto.host',
  apiURL: 'https://api.clasificadoscontacto.host/',
  apiURLv2: 'https://feed.clasificadoscontacto.host/api',
  portal: 'http://localhost:3000',
  staticUrl: 'https://www.static.clasificadoscontacto.host',
  addUser: '?site=M3dqbGViUWx6Vjl2U0FINmZPMDlLdz09',
  typeProperty: '?site=MXVrY21WaW1jeGdmaU10MTZrUmVLWklheE0ybjRDVE5ZeUdCdkdzdmRQST0=',
  countrys: '?site=bDVCSkNsL2Fnb3pRUGNNMDhvUGxOQT09',
  states: '?site=TS8xRFJDWEN4SWJLU09BajZ2NFNYdz09',
  municipalities: '?site=YkVKanNRdmE3U29DV09kVHJxdkVIT3pKNnRkVU0rQXJxRHVpQUFJTmdnZz0=',
  colonys: '?site=ditYbW90MU1LRGp5OTlkTkpET3F1dz09',
  cityandzip: '?site=N1VLZUZ6Y1R2OUQ5aHdjOUd0bDV5VExCNkpxVm13ellVdVUzUzZiTEIzcz0=',
  addProperty: '?site=b3h5NTJLcDdPNHdQblhFSFRZTlBTQT09',
  amenitiesServices: '?site=RW9rK3VLRTMvcFNNQjF2SEJDNXRPc1MzSjZwZmtWdThlVnJKbXlQTHJtbz0=',
  operation: '?site=VUJiVEZqNDdNNk9qVG5yK2NHdmp5Zz09',
  getProducts: '?site=dEI2dk5xT0xxMm9XdW1ZTVc5SkhUTTVMSmttT1FtVmJyK2JMZ25EYnQyVT0=',
  getPropertyEdit: '?site=cnN2eUI5UWZ5TjFFNTk5eDl5SGRERitORGZUSCtINzhOcUdFRU9sQ0J2MD0=',
  updateProperty: '?site=cmFKa2JkZWdDeGdwREhTZGI0QUN2dlZaNEZsb01OY3cxR2szM3pNdXVXOD0=',
  create_user: '?site=bERwdld0UGkwNU5iVmh4cDZzbU1BQT09', // api
  search_properties: '?site=aHoxd1ZFOUdhYlRmNC9zMWRSNmdpTm15VVptaWE4aUhCaysraEk1ZFhJND0=',  // api
  detail_property: '?site=elBvMEtpVmt4Qjk1U3dhMXNENDZQZHRJZDh2QkNHQjlrYWxyemFyUEhqbz0=',
  get_userData: '?site=WVNTeHU2VDAvYXRtZ2hBeitFYzRSQT09',
  get_userDashboard: '?site=Um85QlJ0TUdFMFFxK1VCZzRYdHZQMUk3cHBrcW9lSHUzM2ZtNkQ1aFMxYz0=',
  add_imagesPropertiesEdit: '?site=amVYSkFLL1psemVsQXZqdlRIT0lUL1dIZTVQRG9xUnZLd3JISE5MckxqUT0=',
  delete_property: '?site=Ui9LcURKaTF6c2x1OGlwOTNpelJPMFlReEcrMm5ISkREZWRiSnRKVXdaUT0=',
  get_visit: '?site=emRyZXZVYjRnZUJGSHUrTjhtNUFxUT09',
  edit_user: '?site=d2tVU0plb2w0czIwMmhjWVBuOEdiUT09',
  get_imagePDF: '?site=Z3VpWEp4ckNPVmVJTzgvb3FsYTAxdz09',
  delete_image: '?site=b3lvUXNCNjhGYXc3a29GSzBVMGNVdz09',
  login_admin: '?site=c2VEazVoNnVObWpLZUxUM1BBbWtwUT09',
  add_propertyad: '?site=OTZ0TWZyVE1OZTgyRGEycVM3REZhZkc1cG9ZVE4xNjBEa3FORG9iN1QvST0=',
  get_propertyadmin: '?site=c2d3SllteC9GNGJTQVlMdFQ2M2tPM0t2Vk8xRkY0aEhqeDY0cHRnQXFlcz0=',
  get_archive: '?site=RkNQYzhTMHpUSjJMcE0wNm8ya0k4R1pIeEd5YkNXNmoxQkNkcVRMQXkrVT0=',
  user_image: '?site=OURCRmFPVUF6OS9kL080UFBJOFRXZz09',
  canPost: '?site=NU82dmV4VHJrUFNqYVg2aEpmeSs5Zz09',
  addVehiculo: '?site=dW5CSmVqb0lOQm5vWkFzMGZCanJucmJQWkZ2c3BoNFRiM1J3YjNWQ2Mvbz0=',
  updateVehiculo: '?site=OEwvZHRZWUdXZXB6dmlaYWhHY1V0NWM2L3UzeWdteWp0UGxKbkROQ2kvND0=',
  marcasVehiculos: '?site=SEhrRHYrMWJpY0ZMUlUzUlJKZWxEZz09',
  tiposVehiculos: '?site=eEd6YVBzY2MzRmM2NEhTbzQrbXp0c1dKczlyODZid3hyd1N2eE5adzdTOD0=',
  modelosVehiculos: '?site=MktPTjlBWnU1VXdEanRJUFdHbHZ6dz09',
  carroseriasVehiculos: '?site=elY1UnBkVmZpcWI1S0Z0R25DbzM3dz09',
  seachVehiculos: '?site=SW5TQThCNkhVMHVyQVhXSkRFRGNVVWVzUzgwK2pKMWdEZGIyZzJibVZuRT0=',
  getVehiculoById: '?site=TlQ1ZWVBRS9KZDh6SnNMdUlnYlJqMncwaUh4dTNQYkt2cFpQQU1QSmhpdz0=',
  getTipoRecorridoVehiculos: '?site=b053RjI3K2tGNFFMTUc4S0tVcmNvUT09',
  addMarcasVehiculos: '?site=dTNSUDNUSTRhWFh5YVBFUW1MTHBxZz09',
  addModelosVehiculos: '?site=QkpzSVFqYUhVOXd5dHFBQU5xYVhUdz09',
  valuesFichaTecnica: '?site=SnpGVnNsNmlGbmVNaC9kK09WR1ErQT09',
  getCities: '?site=Z0tVcktNSkNCRitlM2x5Ry9VYjhESkFHcnQ5NEJ5QVJic2JUY1RORFJZdz0=',
  addDev: '?site=OGZtT0Qra0VMMlhWU2xDbGUrRDFCM3ZHRnpNdTFmdGZwMjdXbXAwa1lIUT0=',
  addPrototype: '?site=NWxhSkFOZ1h6WCs5SXlhWTNlWHZ4Zz09',
  getDevs: '?site=a1BoT2JmNmxvL1dRcit0VmIwbkRzSTI1NWVsbXF6UDdLYitTVlVadG5LMD0=',
  typesDev: '?site=WXdhS1Y0NitHYnBySXdNWmEyNEw2OUJpeEthcUxNM3pzS05LYWt3ekcxTT0=',
  getTypeJob: '?site=VXRqcDN1aVdxaERVMGtoNWpOZ1VIQT09',
  getTypePayJob: '?site=VDlWeWZHSU9PbG5sMUpaTEdhbXBMZz09',
  createJob: '?site=cHJHbzl4ZnJFRTQ4Sjk2RkRHNU1pZz09',
  areaJob: '?site=VkRNS0JVK2lmUFJOQ0hCQnJtLzQ2bFh5L0NOSGczOEkyRWQwV0JCQlo0RT0=',
  jobsByArea: '?site=dnNvT014Snc1MWJrcVNlK0d0eCtydz09',
  getAllJobs: '?site=eVVjZGppejhoK1lVYkE1SHJWL0x4Zz09'
};


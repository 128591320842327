import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.css']
})
export class InputSearchComponent implements OnInit {

  @Output() onEnter: EventEmitter<string> = new EventEmitter();
  @Output() onDebounce: EventEmitter<string> = new EventEmitter();

  @Input() placeholder: string = 'Un place holder';

  @Input() items: any[] = [];
  @Output() onItemSelected: EventEmitter<any> = new EventEmitter();


 
  public debouncer: Subject<string> = new Subject();
  public query: string = '';


  constructor() { }

  ngOnInit(): void {
    this.debouncer
      .pipe(
        // Cuantas milesimas de segundo quiero esperar antes de emitir el sisguiente valor
        // Que no haga el susbcribe hasta que pasen 300 ms
        debounceTime(300)
      )
      .subscribe( valor => {
        this.onDebounce.emit( valor );
      })
  }

  // Buscar
  search():void {
    this.onEnter.emit( this.query );
  }

  // Tecla presionada
  keyPress():void {
    // next es el siguiente valor 
    this.debouncer.next(this.query);
  }


  selectItem( item: any ) {
    this.onItemSelected.emit( item );
  }
}

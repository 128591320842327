import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { tap } from 'rxjs/operators';
import swal from "sweetalert2";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IsSellerGuard implements CanActivate {

  constructor( private userService: UserService,
          private router: Router ){}


      canActivate(): Observable<boolean> | boolean {
      return this.userService.isSellerCheck()
      .pipe(
        tap( isSeller => {
          if ( !isSeller ) {
            swal.fire({
              icon: 'info',
              title: 'No estas registrado como vendedor',
              showConfirmButton: false,
              timer: 2000
            })
            
            window.location.href = `${environment.portal}/vender/empezar`;

          }
        })
        
      );

    }
  
}

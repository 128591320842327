import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


const base_url = environment.apiURLv2;


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor( private http: HttpClient ) { }

  private _user: any;
  private _userPackage: any;

  get user(): any { 
    return this._user;
  }

  set user(user: any) {
    this._user = user;
  }

  get userPackage(): any { 
    return this._userPackage;
  }

  set userPackage(userPackage: any) {
    this._userPackage = userPackage;
  }

  validarToken(): any {
    return this.http.get(`${ base_url }/auth/renew`)
    .pipe(
      tap( (resp: any) => {
        // localStorage.setItem('accessToken', resp.accessToken );
        this._user = resp.user;
        console.log({USER: this._user})
      }),
      map( resp => true ),
      catchError( error => of(false) )
    );
  }


  getUserPackage( userId ): any {
    return this.http.get(`${ base_url }/packages/user/${userId}`)
    .pipe(
      tap( (resp: any) => {
        this._userPackage = resp;
      }),
      map( resp => true ),
      catchError( error => of(false) )
    );
  }



  login({ email, password }) {
    return this.http.post(`${ base_url }/auth/login`, { email, password }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }

  googleSingIn( googleToken ) {
    return this.http.post(`${ base_url }/auth/google`, { googleToken }, {
      headers: {
        'Content-Type': 'application/json',
      } 
    })
  }


  adminLogin({ email, password }) {
    return this.http.post(`${ base_url }/auth/login/admin`, { email, password }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }



  isAdminRole(): boolean{
    console.log(this.user)
    if ( this.user?.usertype == 1 ){
      return true
    }
    return false
  }




  resetPasswordTemp({ email  }) {
    return this.http.post(`${ base_url }/auth/reset/password`, { email }, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
  }


}

import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';


declare var google : any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {



  formSubmitted: boolean = false;

  form: FormGroup = this.fb.group({
    'email'   : [ localStorage.getItem('control') || '', [Validators.required, Validators.email]],
    'password'  : ['', Validators.required],
    'remember': [false],
  });

  constructor(  private fb: FormBuilder,
                private authService: AuthService,
                private router: Router,
                private ngZone: NgZone ) { }


  ngOnInit(): void {
    const gAccounts: any = google?.accounts;

    gAccounts.id.initialize({
      client_id: '716414210151-gqkifk9uuajdur8gm2lv9c291ghe0cpp.apps.googleusercontent.com',
      ux_mode: 'popup',
      cancel_on_tap_outside: true,
      callback: ({ credential }) => {
        this.ngZone.run(() => {
          this.handleCredentialResponse(credential);
        });
      },
    });

    gAccounts.id.renderButton(document.getElementById('gbtn') as HTMLElement, {
      size: 'large',
      width: 320,
    });
  }


  async handleCredentialResponse(credentials) {
    this.authService.googleSingIn( credentials )
      .subscribe( (resp: any) => {
        localStorage.setItem('accessToken', resp.accessToken)
        this.router.navigate(['./user/dashboard']);
      })
  }


  campoValido( campo: string ): boolean {
    return this.form.controls[campo].errors && this.form.controls[campo].touched;
  }


  mensajesError( campo: string  ): string {
    return this.form.get(campo)?.hasError('required') ? `Este campo es requerido.` :
            this.form.get(campo)?.hasError('email') ? `Correo electrónico no válido` : ''
  }



  login(): void {

    this.formSubmitted = true;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.formSubmitted = false;
      return;
    }

    

    this.authService.login(this.form.value)
      .subscribe( (resp: any) => {
        if (this.form.get('remember')?.value) {
          localStorage.setItem('email', this.form.get('email')?.value )    
        } else {
          localStorage.removeItem('email');
        }
        Swal.close()
        localStorage.setItem('accessToken', resp.accessToken)
        document.cookie = `accessTokenCC=${resp.accessToken};`;
        document.cookie = `accessTokenCC=${resp.accessToken};domain=.clasificadoscontacto.com`;
        document.cookie = `accessTokenCC=${resp.accessToken};domain=.clasificadoscontacto.host`;
        this.router.navigate(['./user/dashboard']);
        
      }, err => {
        this.formSubmitted = false;
        Swal.fire({
          text: err.error.message,
          icon: 'error'
        });

      }); 
  }



}

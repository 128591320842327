import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { SidebarService } from 'src/app/services/shared/sidebar.service';

@Component({
  selector: 'app-adminroot-layout',
  templateUrl: './adminroot-layout.component.html',
  styleUrls: ['./adminroot-layout.component.css']
})
export class AdminrootLayoutComponent implements OnInit {

  constructor(private route:Router,
            public sidebarService: SidebarService ) {
    // if(localStorage.getItem('usertype')){
      
    // }else{
    //   localStorage.clear();
    //   //window.location.href = 'https://www.clasificadoscontacto.host';
    //   this.route.navigate(['login']);
    // }
  }

  ngOnInit(): void {
  }

}

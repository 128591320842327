
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Cookies from 'js-cookie';


@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    // const token = localStorage.getItem('accessToken') || ':)';

    const accessTokenCC = Cookies.get('accessTokenCC');

    const headers = new HttpHeaders({
      Authorization: `Bearer ${ accessTokenCC }`
    });
 
    const requestClone = request.clone({
      headers
    });
    
    return next.handle(requestClone);
  }


  getCookieByName( name: string ) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  getCookieValue(name: any) {
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
  }


}
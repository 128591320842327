import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons }from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { SidebarService } from 'src/app/services/shared/sidebar.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit, AfterViewInit {

  closeResult: string;
  public isCollapsed = false;
  private listTitles: any[];
  public location: Location;
  public isLoading: boolean = true;
  public canPost: boolean = false;
  public name: string = "";
  public portal= environment.portal;

  constructor(location: Location,  
      private element: ElementRef, 
      private router: Router,
      public modalService: NgbModal, 
      public userService: UserService,
      public sidebarService: SidebarService,
      public authService: AuthService ) {
    this.location = location;
  }


  ngAfterViewInit(): void {
    this.userService.canPost()
      .subscribe( resp => {
      this.canPost = resp;
      this.isLoading = false;
    });

  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.router.events.subscribe((event) => {
      // this.sidebarClose();
       var $layer: any = document.getElementById('bodyClick');
       if ($layer) {
         $layer.remove();
       }
   });

   this.name = this.authService.user.name
   console.log(this.name);


  }


  goTo(section){
    if ( !this.userService.userCanPost ) return;
    this.router.navigate([section]);
  }
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }
      for (let i = 0; i < this.listTitles.length; i++) {
          if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
              return this.listTitles[i].title;
          } else if (this.listTitles[i].type === "sub") {
              for (let j = 0; j < this.listTitles[i].children.length; j++) {
                  let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
                  if (subtitle === titlee) {
                      return this.listTitles[i].children[j].title;
                  }
              }
          }
      }
      return 'Dashboard';
  }
  collapse(){
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    }else{
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }

  }
 
  

  close(){
    this.deleteCookie('accessTokenCC');
    // localStorage.removeItem('accessToken')
    window.location.href = this.portal;
   
  }


  deleteCookie(name: string){
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.clasificadoscontacto.com';
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.clasificadoscontacto.host';
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };


  sidebarControl() {
    if ( this.sidebarService.show ){
      this.sidebarService.close();
    } else {
      this.sidebarService.open();
    }
  }



  goToPublish(){
    this.router.navigateByUrl('/user/productos/secciones')
  }

}

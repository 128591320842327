import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {



  formSubmitted: boolean = false;

  form: FormGroup = this.fb.group({
    'email'   : [ localStorage.getItem('control') || '', [Validators.required, Validators.email]],
  });

  constructor(  private fb: FormBuilder,
                private authService: AuthService,
                private router: Router ) { }


  ngOnInit(): void {

  }



  campoValido( campo: string ): boolean {
    return this.form.controls[campo].errors && this.form.controls[campo].touched;
  }


  mensajesError( campo: string  ): string {
    return this.form.get(campo)?.hasError('required') ? `Este campo es requerido.` :
            this.form.get(campo)?.hasError('email') ? `Correo electrónico no válido` : ''
  }



  submit(): void {

    this.formSubmitted = true;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.formSubmitted = false;
      return;
    }

    

    this.authService.resetPasswordTemp(this.form.value)
      .subscribe( (resp: any) => {


        if ( resp.status == 404) {

          Swal.fire({
            title: 'Oops!',
            text: resp.message,
            icon: 'error'
          })
            this.formSubmitted = false;
          return;
        }
        
        Swal.fire({
          title: 'Éxito',
          text: 'Te hemos enviado un correo electrónico con los pasos para restablecer tu contraseña.',
          icon: 'success'
        })

        
        this.formSubmitted = false;
  
        this.router.navigateByUrl('/iniciar-sesion')
      }, err => {
        this.formSubmitted = false;
        Swal.fire({
          text: err.error.message,
          icon: 'error'
        });

      }); 
  }



}

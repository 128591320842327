import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from './../../../environments/environment';
import { UserData } from './../../interfaces/user-data';
import { UserDashboard } from './../../interfaces/user-dashboard';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { tarifa } from 'src/app/interfaces/commisions.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseURL = environment.apiURL;
  apiUrl= environment.apiURLv2;

  public user = null;
  public userCanPost: boolean = false;
  public userPackageId: number = 0;

  constructor(private http: HttpClient,
            private authService: AuthService) { }


    // TODO
  getUserData(id): Observable<UserData>{
    const formData = new FormData();
    formData.append('folio', id);
    return this.http.post<UserData>(this.baseURL + environment.get_userData,formData)
      .pipe( 
        map( (resp: any) => {
          this.user = resp.data;
          return resp;
        })
      );
  }

  // Verificar si puede publicar anuncios
  canPost() {

    const formData = new FormData();
    formData.append('folio', this.authService.user.userid );

    return this.http.post(`${ this.baseURL }${ environment.canPost }`, formData)
    .pipe(
      map( (resp: any) => {
        this.userPackageId = Number(resp.data[0].packageid);
        this.userCanPost = resp.data[0].success == 1;

        if ( !this.userPackageId ) {
          this.userCanPost = true;
          this.userPackageId = 1;
        }
        return this.userCanPost;
      } ), // of permite crear un observable
      catchError( error => of(false) )
    );

  }

  isSellerCheck() {
    return this.http.get(`${ this.apiUrl }/users/isSeller`)
    .pipe(
      map( (resp: any) => {
        return resp.isSeller;
      } ), // of permite crear un observable
      catchError( error => of(false) )
    );
  }


  adminSingin(data): Observable<UserData>{
    const formData = new FormData();
    for(let elemento in data){
      if(data[elemento] == undefined || data[elemento] == null){
        data[elemento] = '';
      }
      formData.append(elemento, data[elemento]);
    }
    return this.http.post<UserData>(this.baseURL + environment.login_admin,formData);
  }

  getUserDashboard(id): Observable<UserDashboard>{
    const formData = new FormData();
    formData.append('folio', id);
    return this.http.post<UserDashboard>(this.baseURL + environment.get_userDashboard,formData);
  }

  getVisitsData(id): Observable<UserData>{
    const formData = new FormData();
    formData.append('folio', id);
    return this.http.post<UserData>(this.baseURL + environment.get_visit,formData);
  }

  editUserProfile(user): Observable<UserData>{
    const formData = new FormData();
    for(let elemento in user){
      if(user[elemento] == undefined || user[elemento] == null){
        user[elemento] = '';
      } 
      formData.append(elemento, user[elemento]);
    }
    return this.http.post<UserData>(this.baseURL + environment.edit_user,formData);
  }


  updateProfileImage( id: any, image: File ) {
    const formData = new FormData();
    formData.append('folio', id);
    formData.append('image', image, image.name);
    return this.http.post(this.baseURL + environment.user_image, formData)
      .pipe(
        catchError( error =>{ 
          return of(false)} )
      );
  }


  getUserImage(id: string): Observable<UserData>{
    const formData = new FormData();
    formData.append('folio', id);
    return this.http.post(this.baseURL + environment.get_userData,formData)
      .pipe(
        map( (resp: any) => resp.data['SDx_image'] )
      )
  }

  getCommisions(){
    return this.http.get<tarifa>(this.apiUrl+'/commissions/packages')
  }

  calulateCommisions(price:any, package_product_id:any){
    return this.http.get(this.apiUrl+`/commissions/calculate_comission?price=${price}&package_product_id=${package_product_id}`)
  }


}

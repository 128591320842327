import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/services/auth/auth.service";
import { SidebarService } from "src/app/services/shared/sidebar.service";

var misc:any ={
    sidebar_mini_active: true
}

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype?: string;
  image?: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/user/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "fa-solid fa-chart-line"
  },
  {
    path: "/user/contactos",
    title: "Contactos",
    type: "link",
    icontype: "fa-solid fa-address-book"

  },
  {
    path: "/user/productos/propiedades",
    title: "Inmuebles",
    type: "link",
    icontype: "fa-solid fa-house-chimney"
  },
  {
    path: "/user/productos/vehiculos",
    title: "Vehículos",
    type: "link",
    icontype: "fa-solid fa-car"
  },
  {
    path: "/user/productos/empleos",
    title: "Empleos",
    type: "link",
    icontype: "fa-solid fa-briefcase"
  },
  {
    path: "/user/productos/general",
    title: "Productos",
    type: "link",
    icontype: "fa-solid fa-tv"
  }
];

export const adminROUTES: RouteInfo[] = [
  {
    path: "/admin/products",
    title: "Publicaciones",
    type: "link",
    icontype: "fa-solid fa-house-chimney"
  },
  {
    path: "/admin/sections",
    title: "Marcas",
    type: "link",
    icontype: "fa-solid fa-car"
  },
  {
    path: "/admin/devs",
    title: "Desarrollos",
    type: "link",
    icontype: "fa-solid fa-house-chimney"
  },
  {
    path: "/admin/productos-config",
    title: "Productos",
    type: "link",
    icontype: "fa-solid fa-tv"
  }
]

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;

  constructor(
      private toastr: ToastrService, 
      private sidebarService: SidebarService,
      private authService: AuthService ) {}

  ngOnInit() {
    if(  this.authService.user.usertype ){
      this.menuItems = adminROUTES.filter(menuItem => menuItem);
    }else{
      this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    
  }
  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
  myFunc(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.sleep(10);
    if (menuitem.isCollapsing === undefined) {
      menuitem.isCollapsing = true;

      // menuitem.isCollapsed = !menuitem.isCollapsed;

      var element = event.target;
      while (
        element.getAttribute("data-toggle") != "collapse" &&
        element != document.getElementsByTagName("html")[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (
        element.classList.contains("collapse") &&
        !element.classList.contains("show")
      ) {
        element.classList = "before-collapsing";
        var style = element.scrollHeight;

        element.classList = "collapsing";
        setTimeout(function() {
          element.setAttribute("style", "height:" + style + "px");
        }, 1);
        setTimeout(function() {
          element.classList = "collapse show";
          element.removeAttribute("style");
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        var style = element.scrollHeight;
        setTimeout(function() {
          element.setAttribute("style", "height:" + (style + 20) + "px");
        }, 3);
        setTimeout(function() {
          element.classList = "collapsing";
        }, 3);
        setTimeout(function() {
          element.removeAttribute("style");
        }, 20);
        setTimeout(function() {
          element.classList = "collapse";
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }
  minimizeSidebar(){
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
        misc.sidebar_mini_active = true
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
        body.classList.remove('sidebar-mini');
        misc.sidebar_mini_active = false;
        this.showSidebarMessage('Sidebar mini deactivated...');
    } else {
            body.classList.add('sidebar-mini');
            this.showSidebarMessage('Sidebar mini activated...');
            misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function() {
        window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function() {
        clearInterval(simulateWindowResize);
    }, 1000);
  }

  showSidebarMessage(message){
    this.toastr.show(
      '<span class="now-ui-icons ui-1_bell-53"></span>', message,
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right"
      }
    );
  }


  sidebarControl() {
    if ( this.sidebarService.show ){
      this.sidebarService.close();
    } else {
      this.sidebarService.open();
    }
  }
}
